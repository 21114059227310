























































































































































































import Vue from "vue";
import PageHeading from "@/components/PageHeading.vue";
import { apiAxios } from "@/store/apiAxios";
import { BIconEyeFill } from "bootstrap-vue";
import authService from "@/services/AuthService";
import { mapGetters } from "vuex";
import i18n from "@/i18n";

export default Vue.extend({
  name: "UserProfile",
  components: { PageHeading, BIconEyeFill },
  data: () => ({
    userForm: {
      sms: false,
      app: false,
      emailLogin: false,
      currentPassword: "",
      newPassword: "",
    },
    showPassword: false,
    showNewPassword: false,
    responsePassword: "",
    qrCode: null,
    recoveryCodes: [],
    user: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      fullname: "",
      filled: false,
    },
    required: "",
  }),

  props: {
    title: { type: String },
  },
  beforeMount() {
    // GET CONTACT INFORMATION FROM ACTIVE CAMPAIGN
    authService.getContactInformationUserAuth().then((r) => {
      this.user = r.data;
      this.user.fullname = this.user.firstName + " " + this.user.lastName;
      this.user.filled = true;
    });

    //GET USER INFORMATION FROM OUR DB
    if (this.userAuth.two_factor_method == "sms") {
      this.userForm.sms = true;
      this.userForm.app = false;
      this.userForm.emailLogin = false;
    } else if (this.userAuth.two_factor_method == "app") {
      this.userForm.app = true;
      this.userForm.sms = false;
      this.userForm.emailLogin = false;
    } else if (this.userAuth.two_factor_method == "email") {
      this.userForm.emailLogin = true;
      this.userForm.app = false;
      this.userForm.sms = false;
    }
  },
  mounted() {
    this.$store.state.auth.user.two_factor_secret === null
      ? (this.userForm.app = false)
      : (this.userForm.app = true);
  },
  computed: {
    ...mapGetters({
      userAuth: "auth/authUser",
    }),
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
  },
  methods: {
    save() {
      let two_fa = "";

      if (
        this.userForm.sms === true &&
        this.userForm.app === false &&
        this.userForm.emailLogin === false
      ) {
        two_fa = "sms";
      } else if (
        this.userForm.app === true &&
        this.userForm.sms === false &&
        this.userForm.emailLogin === false
      ) {
        two_fa = "app";
      } else if (
        this.userForm.app === false &&
        this.userForm.sms === false &&
        this.userForm.emailLogin === true
      ) {
        two_fa = "email";
      }

      if (
        (this.userForm.currentPassword.length > 0 &&
          this.userForm.newPassword.length > 0) ||
        two_fa !== this.userAuth.two_factor_method
      ) {
        apiAxios
          .post("/api/user/update", {
            password: this.userForm.currentPassword,
            "new-password": this.userForm.newPassword,
            two_fa: two_fa,
          })
          .then(async(r) => {
            if(two_fa == "app") {
              this.enable2fa();
            } else {
              if (r.data == "mismatch" || r.data == "characters") {
                this.responsePassword = r.data;
              } else {
                window.location.reload();
              }
            }
            
          });
      } else {
        this.required = i18n.t("profileEdit.nothing-to-save");
      }
    },

    switchCheckboxValue(e: string) {
      switch (e) {
        case "sms":
          this.userForm.app = false;
          this.userForm.emailLogin = false;
          break;
        case "app":
          this.userForm.sms = false;
          this.userForm.emailLogin = false;
          break;
        case "email":
          this.userForm.app = false;
          this.userForm.sms = false;
          break;
      }
    },

    enable2fa() {
      if (this.userForm.app == true) {
        apiAxios.post("/user/two-factor-authentication").then((res) => {
          this.$bvModal.show("2fa-modal");
          res.status == 200
            ? (this.userForm.app = true)
            : (this.userForm.app = false);
          this.fetch2faQRCode();
        });
      } else {
        apiAxios.delete("/user/two-factor-authentication").then((res) => {
          res.status == 200
            ? (this.userForm.app = false)
            : (this.userForm.app = true);
        });
      }
    },
    fetch2faQRCode() {
      let call = apiAxios
        .get("/user/two-factor-qr-code")
        .then((res) => {
          this.qrCode = res.data.svg;
        })
        .then(() => {
          apiAxios.get("/user/two-factor-recovery-codes").then((res) => {
            this.recoveryCodes = res.data;
          });
        });
      return call;
    },
    closeModalAndRefresh() {
      this.$bvModal.hide("2fa-modal");
      window.location.reload();
    },
  },
});
